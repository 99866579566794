import React from "react"
import Layout from "../../components/layout";
import Promo from "../../components/promo";
import Feature from "../../components/feature";
import Title from "../../components/title";
import { Helmet } from 'react-helmet';
import { Link } from "gatsby";

import Clients from "../../../static/icons/clients.inline.svg";
import Healthcheck from "../../../static/icons/healthcheck.inline.svg";
import Reminders from "../../../static/icons/reminders.inline.svg";
import Projects from "../../../static/icons/projects.inline.svg";
import projects from "../../../static/product/projects.svg";
import healthcheck from "../../../static/product/healthcheck.svg";
import reminders from "../../../static/product/reminders.svg";
import Arrow from "../../../static/icons/arrow.inline.svg";

const Product = ({ location }) => (
    
    <Layout 
      path={location.pathname}>

      <Helmet
        bodyAttributes={{
          class: 'bg-root text-white'
        }} />
      
      <Title
        sub="More"
        colour="yellow-500"
        title="Insights on projects, clients & more."
        width="max-w-xl">

        <Link to="https://app.heyslate.com/register" className="max-w-xl mx-auto flex items-center justify-center space-x-2 text-accent md:text-lg mt-6">
          <span clasName="text-accent">Get started for free</span>
          <Arrow/>
        </Link>

      </Title>

      <div className="lg:container max-w-6xl mx-auto md:text-lg">

        <div className="lg:flex lg:space-x-8">

          <div className="lg:w-1/2 space-y-4 sm:space-y-8">

            <Feature 
              icon={<Projects />}
              title="Stress free project management."
              text="A simple way of tracking how much time you've spent on a project, 
              and knowing if it's been profitable. If you set a deadline on a project, Slate will let you know if it 
              looks like you're not going to meet the deadline based on your current schedule, saving you an awkward conversation
              with your client."
              tight={true}
              image={projects}
              colour="yellow-500" />

            <Feature 
              icon={<Clients />}
              title="Smart client insights."
              text="Slate gives you a simple breakdown of your most popular clients, so you know which ones are generating the most
              revenue for your business and which are taking up most of your time."
              tight={false}
              colour="yellow-500" />

          </div>

          <div className="lg:w-1/2 space-y-4 sm:space-y-8">

            <Feature 
              icon={<Healthcheck />}
              title="Real-time healthchecks."
              text="Get a real-time view of your business whenever you add work to your schedule, highlighting any potential issues ahead of time so you can deal with them."
              tight={false}
              image={healthcheck}
              colour="yellow-500" />

            <Feature 
              icon={<Reminders />}
              title="Super simple todo list."
              text="Most todo apps are over complicated for freelancers. Slate gives you an easy way to create a list of things you 
              need to get around to, without the pressure of setting deadlines and sub-tasks."
              tight={false}
              image={reminders}
              colour="yellow-500" />

          </div>
          
        </div>

      </div>

      <Promo theme="dark" />

    </Layout>
)

export default Product;